import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Styled404>
      <h1>404: Podstrona nie istnieje</h1>
      <p>Niestety wybrana podstrona nie istnieje - <Link to="/">Przejdź do strony głównej Przychodni Lekarskiej Medica</Link> </p>
    </Styled404>
  </Layout>
);

export default NotFoundPage;

const Styled404 = styled.div`
  /* quickfix */
  color: var(--background);

  a{
    color: var(--background);
  }
`;
